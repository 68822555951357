import { render, staticRenderFns } from "./CUploadPicture.vue?vue&type=template&id=37f46d4a"
import script from "./CUploadPicture.vue?vue&type=script&lang=js"
export * from "./CUploadPicture.vue?vue&type=script&lang=js"
import style0 from "./CUploadPicture.vue?vue&type=style&index=0&id=37f46d4a&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOPartner\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37f46d4a')) {
      api.createRecord('37f46d4a', component.options)
    } else {
      api.reload('37f46d4a', component.options)
    }
    module.hot.accept("./CUploadPicture.vue?vue&type=template&id=37f46d4a", function () {
      api.rerender('37f46d4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CUploadPicture.vue"
export default component.exports