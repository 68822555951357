import $comm from '@/js/common';
import swal from "sweetalert2";
import toastr from "toastr";
import 'sweetalert2/src/sweetalert2.scss'

var commMessage = {
  notify: null,
  exceptionNotify: null,
  alert: null,
  confirm: null,
  toast: null
};

const Toast = swal.mixin({
toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
})

const Alert = swal.mixin({
  confirmButtonColor: '#03A9F4',
  customClass: {
    cancelButton: 'swal-custom-cancel-btn'
  }
})

/**
 * function name : notify
 * desc : 오른쪽 상단에 표시되는 알림이며 일정시간이 지날시 사라짐
 * parameters
 *  _option :
 *    - title : 상단 표시 라벨
 *    - message : 본문 내용
 *    - type : success/warning/info/error
 *    - duration : 표시되는 시간 (단위 ms)
 */
commMessage.notify = function (target, _option) {
  if (!_option) {
    _option = {
      title: 'L0000003396',
      message: $comm.getLangSpecInfoMessage('M0000000016'),
      type: 'error',
    }
  }
  if (process.env.NODE_ENV === 'production') {
    // 운영 서버인 경우에는
    // [''] ** 현재는 notify를 exception발생하였을 경우에만 사용함으로 이후에 notify를 추가로 사용하는 경우 default문구 생성
    // 라는 문구의 메시지가 뜨도록 설정
    _option.message = $comm.getLangSpecInfoMessage('M0000000016');
  }
  toastr.options.closeButton = true;
  toastr.options.progressBar = true;
  toastr.options.positionClass = 'toast-top-right';
  toastr.options.timeOut = 10000;
  toastr.options.fadeOut = 250;
  toastr.options.fadeIn = 250;
  toastr[_option.type ? _option.type : 'error'](_option.message)
};

/**
 * function name : exceptionNotify
 * desc : 오른쪽 상단에 표시되는 알림이며 예외상항에 대해 표시한다.
 *         일정시간이 지날시 사라짐
 *         ##개발모드인 경우에만 사용##
 * parameters
 *  _option :
 *    - title : 상단 표시 라벨
 *    - message : 본문 내용
 *    - type : success/warning/info/error
 *    - duration : 표시되는 시간 (단위 ms)
 */
commMessage.exceptionNotify = function (target, _option) {
  var type = '';
  var _errMsg = '';
  if (_option && _option.hasOwnProperty('type')) type = _option.type;

  if (_option.message.message === 'Network Error') {
    this.alert(target, {
      title: '네트워크 접속오류', 
      message: '네트워크 접속문제로 요청된 정보를 찾을 수 없습니다.\n\r관리자에게 문의해 주시기 바랍니다.', 
      type: 'error',
    });
    return;
  }
  if (_option && _option.message) {
    _errMsg = '<table class="table table-bordered"><tbody>';
    _errMsg +=
    '<tr><th scope="row" class="q-pa-sm">Status</th><td colspan="3">' +
      _option.errorDetail.status +
      '</td>';
    _errMsg +=
      '<tr><th scope="row" class="q-pa-sm">Message</th><td colspan="3">' +
      _option.message.message +
      '</td></tr>';
    _errMsg +=
      '<tr><th scope="row" class="q-pa-sm">Cause</th><td colspan="3">' +
      _option.message.cause +
      '</td></tr>';
    _errMsg +=
      '<tr><th scope="row" class="q-pa-sm">CauseDetail</th><td colspan="3">' +
      _option.message.causeDetail +
      '</td></tr>';
    _errMsg +=
      '<tr><th scope="row" class="q-pa-sm">Url</th><td colspan="3">' +
      (_option.message.url ? _option.message.url : (_option.errorDetail && _option.errorDetail.config ? '[' + _option.errorDetail.config.method + '] ' + _option.errorDetail.config.url : '')) +
      '</td></tr>';
    // _errMsg +=
    //   '<tr><th scope="row">QueryString</th><td colspan="3">' +
    //   _option.message.queryString +
    //   '</td></tr>';
    // _errMsg +=
    //   '<tr><th scope="row">반환코드</th><td>' +
    //   _option.message.returnCode +
    //   '</td>';
    // _errMsg +=
    //   '<th scope="row">로그번호</th><td>' +
    //   _option.message.logNo +
    //   '</td></tr>';
    _errMsg += '</tbody></table>';
  } else {
    _errMsg = _option;
  }


  this.notify(target, {
    title: $comm.getLangLabel(_option.title),
    message: _errMsg,
    type: type,
    duration: _option && _option.hasOwnProperty('duration') ? _option.duration : 3000,
  });
};

/**
 * function name : alert
 * desc : 중앙에 표시되는 알림이며 confirm button 클릭 시 사라짐
 * parameters
 *  _option :
 *    - title : 상단 표시 라벨
 *    - message : 본문 내용
 *    - type : success/warning/info/error
 */
commMessage.alert = function (target, _option) {
  if (_option.type === 'success') {
    Toast.fire({
      title: $comm.getLangLabel('안내'), // $comm.getLangLabel(_option.title), Alert의 경우 '안내' title을 사용
      html: $comm.convertEnter($comm.getLangMessage(_option.message)),
      icon: _option.type,
      confirmButtonText: '확인', // 확인
    })
  } else {
    Alert.fire({
      title: $comm.getLangLabel(_option.title),
      html: $comm.convertEnter($comm.getLangMessage(_option.message)),
      icon: _option.type,
      confirmButtonText: '확인', // 확인
    })
  }
};

/**
 * function name : confirm
 * desc : 중앙에 표시되는 확인창
 * parameters
 *  _option :
 *    - title : 상단 표시 라벨
 *    - message : 본문 내용
 *    - type : success/warning/info/error
 *    - confirmCallback : 확인 시 진행할 function
 *    - cancelCallback : 취소 시 진행할 function
 */
commMessage.confirm = function (target, _option) {
  Alert.fire({
    title: $comm.getLangLabel(_option.title),
    html: $comm.convertEnter($comm.getLangMessage(_option.message)),
    icon: "question",
    confirmButtonText: $comm.getLangLabel('적용'), // 적용
    showCancelButton: true,
    cancelButtonText: $comm.getLangLabel('취소'), // 취소
    cancelButtonColor: '#EEEEEE',
  })
  .then((_result) => {
    if (_result.isConfirmed) {
      typeof _option.confirmCallback === 'function'
        ? _option.confirmCallback()
        : null
    } else {
      typeof _option.cancelCallback === 'function'
        ? _option.cancelCallback()
        : null
    }
  });
};

/**
 * function name : toast
 * desc : 간단한 내용을 올리는 알림창
 * parameters
 *  _option :
 *    - title : 상단 표시 라벨
 *    - icon : success / info / warning / error / question
 */
commMessage.toast = function (target, _option) {
  Toast.fire({
    title: _option.title,
    icon: _option.type,
  })
};

export default commMessage;
